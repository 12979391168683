! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right",
			"pagedim-black"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		},
		// handle menu close gracefully
		hooks: {
			"close:after": function() {
				$mobileMenuTrigger.removeClass("w--open");
			},
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767) {
		$(".content-wrapper").fitVids({ ignore: "nofit" });
	}

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-drop-toggle-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-drop-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Search form
	$(".search-trigger").click(function(event) {
		$(".expanding-search").slideToggle();
	});

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Back to top link
	$("#topLink").click(function() {
		$('html,body').animate({
			scrollTop: $("#Top").offset().top
		},
		800
		);
	});

	if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
		$('body').on("mousewheel", function() {
			event.preventDefault();
			var wd = event.wheelDelta;
			var csp = window.pageYOffset;
			window.scrollTo(0, csp - wd);
		});
	}

	/* Sticky header */
	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 30);
	});

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	Webflow.require('ix').init([
		{ slug: "dropdown-hover", name: "Dropdown Hover", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "new-dropdown", name: "New Dropdown", value: { style: {}, triggers: [{ type: "dropdown", stepsA: [{ opacity: 0, height: "0px" }, { opacity: 1, height: "auto", transition: "opacity 200ms ease 0ms, height 200ms ease 0ms" }], stepsB: [{ height: "0px", transition: "opacity 100ms ease 0ms, height 100ms ease 0ms" }] }] } },
		{ slug: "dropdown-list", name: "Dropdown List ", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "nav-search-button", name: "Nav Search Button", value: { style: {}, triggers: [{ type: "click", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }, { type: "click", stepsA: [{ opacity: 1 }], stepsB: [{ opacity: 0.55 }] }] } },
		{ slug: "md-reveal-doctor-search", name: "MD Reveal Doctor Search", value: { style: {}, triggers: [{ type: "click", stepsA: [{ display: "block", height: "0px" }, { height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }, { display: "none" }] }] } },
		{ slug: "md-tooltip-button-hover", name: "MD Tooltip Button Hover", value: { style: {}, triggers: [{ type: "hover", descend: true, stepsA: [{ display: "block", opacity: 0, x: "0px", y: "1.5rem", z: "0px" }, { wait: 150 }, { opacity: 1, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "0px", z: "0px" }], stepsB: [{ wait: 150 }, { opacity: 0, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "1.5rem", z: "0px" }, { display: "none", opacity: 1, x: "0px", y: "0px", z: "0px" }] }, { type: "click", descend: true, stepsA: [{ display: "block", opacity: 0, x: "0px", y: "1.5rem", z: "0px" }, { wait: 150 }, { opacity: 1, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "0px", z: "0px" }], stepsB: [{ opacity: 0, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "1.5rem", z: "0px" }, { display: "none", opacity: 1, x: "0px", y: "0px", z: "0px" }] }] } },
		{ slug: "reveal-left-nav", name: "Reveal Left Nav", value: { style: {}, triggers: [{ type: "click", selector: ".left-nav-wrapper", siblings: true, stepsA: [{ height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }] } },
		{ slug: "md-patient-comments-reveal-on-load", name: "MD Patient Comments Reveal (on load)", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "md-show-more-button", name: "MD Show More Button", value: { style: {}, triggers: [{ type: "click", stepsA: [{ height: "auto", transition: "height 700ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "loc-reveal-sidebar-search", name: "LOC Reveal Sidebar Search", value: { style: {}, triggers: [{ type: "click", selector: ".loc-sidebar-form-reveal", stepsA: [{ height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }, { type: "click", selector: ".loc-refine-search-button-image", preserve3d: true, stepsA: [{ transition: "transform 500ms ease 0ms", rotateX: "0deg", rotateY: "0deg", rotateZ: "90deg" }], stepsB: [{ transition: "transform 500ms ease 0ms", rotateX: "0deg", rotateY: "0deg", rotateZ: "0deg" }] }] } },
		{ slug: "for-two-part-button-toggle", name: "For Two Part Button Toggle", value: { style: {}, triggers: [{ type: "click", selector: ".two-part-button-list", siblings: true, preserve3d: true, stepsA: [{ transition: "transform 750ms ease 0", x: "-100%", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "for-two-part-button-list", name: "For Two Part Button List", value: { style: { x: "100%", y: "0px", z: "0px" }, triggers: [] } },
		{ slug: "for-two-part-list-back-button", name: "For Two Part List Back Button", value: { style: {}, triggers: [{ type: "click", selector: ".two-part-button-list", preserve3d: true, stepsA: [{ transition: "transform 750ms ease 0", x: "100%", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "display-none-on-page-load", name: "Display None On Page Load", value: { style: { display: "none" }, triggers: [] } },
		{ slug: "for-grid-view-button", name: "For Grid View Button", value: { style: { opacity: 0.750000000000001 }, triggers: [{ type: "click", selector: ".shop-item-row-wrap-list-view", stepsA: [{ display: "none" }], stepsB: [] }, { type: "click", selector: ".shop-item-row-wrap-grid-view", stepsA: [{ display: "flex" }], stepsB: [] }, { type: "click", selector: ".pagination-view-by-button", siblings: true, stepsA: [{ opacity: 0.3500000000000003, transition: "opacity 200ms ease 0ms" }], stepsB: [] }, { type: "click", stepsA: [{ opacity: 0.750000000000001, transition: "opacity 200ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "for-list-view-button", name: "For List View Button", value: { style: {}, triggers: [{ type: "click", selector: ".shop-item-row-wrap-grid-view", stepsA: [{ display: "none" }], stepsB: [] }, { type: "click", selector: ".shop-item-row-wrap-list-view", stepsA: [{ display: "flex" }], stepsB: [] }, { type: "click", selector: ".pagination-view-by-button", siblings: true, stepsA: [{ opacity: 0.3500000000000003, transition: "opacity 200ms ease 0ms" }], stepsB: [] }, { type: "click", stepsA: [{ opacity: 0.750000000000001, transition: "opacity 200ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "expand-search-bar", name: "Expand Search Bar", value: { style: {}, triggers: [] } },
		{ slug: "preloader-wrap", name: "Preloader Wrap", value: { style: { display: "flex" }, triggers: [{ type: "load", preload: true, stepsA: [{ wait: 750 }, { opacity: 0, transition: "opacity 750ms ease 0ms" }, { display: "none" }], stepsB: [] }] } },
		{ slug: "mobile-search-reveal", name: "Mobile Search Reveal", value: { style: {}, triggers: [{ type: "click", selector: ".header-mobile-search", stepsA: [{ height: "0px" }, { height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }, { type: "click", selector: ".nav-search-button-icon", descend: true, stepsA: [{ display: "none" }], stepsB: [{ display: "block" }] }, { type: "click", selector: ".nav-search-button-close-icon", descend: true, stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "hide-mobile-header-on-load", name: "Hide Mobile Header On Load", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "nav-resize-trigger", name: "Nav Resize Trigger", value: { style: {}, triggers: [{ type: "scroll", selector: ".nav-container", stepsA: [{ height: "90px", transition: "height 700ms ease 0" }], stepsB: [{ height: "60px", transition: "height 700ms ease 0" }] }, { type: "scroll", selector: ".nav-section", preserve3d: true, stepsA: [{ transition: "transform 350ms ease 0", x: "0px", y: "0px", z: "0px" }], stepsB: [{ transition: "transform 500ms ease 0", x: "0px", y: "-50px", z: "0px" }] }, { type: "scroll", selector: ".header-contents-wrap", siblings: true, preserve3d: true, stepsA: [{ transition: "transform 500ms ease 0", x: "0px", y: "0px", z: "0px" }], stepsB: [{ transition: "transform 250ms ease 0", x: "0px", y: "-50px", z: "0px" }] }, { type: "scroll", selector: ".logo-image", preserve3d: true, stepsA: [{ opacity: 1, transition: "opacity 700ms ease 0, transform 200 ease 0", scaleX: 1, scaleY: 1, scaleZ: 1 }], stepsB: [{ transition: "transform 350ms ease 0", scaleX: 0.7000000000000004, scaleY: 0.7000000000000004, scaleZ: 1 }] }] } },
		{ slug: "sticky-header-bump-from-top", name: "Sticky Header Bump From Top", value: { style: {}, triggers: [] } },
		{ slug: "nav-section-on-page-load", name: "Nav Section On Page Load", value: { style: { x: "0px", y: "-50px", z: "0px" }, triggers: [] } },
		{ slug: "nav-container-on-load", name: "Nav Container On Load", value: { style: { height: "60px" }, triggers: [] } },
		{ slug: "header-contents-wrap-on-page-load", name: "Header Contents Wrap on Page Load", value: { style: { opacity: 0 }, triggers: [{ type: "load", stepsA: [{ x: "0px", y: "-50px", z: "0px" }, { wait: "100ms" }, { opacity: 1, transition: "transform 500ms ease 0, opacity 200 ease 0", x: "0px", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "account-dropdown-list", name: "Account Dropdown List", value: { style: {}, triggers: [] } },
		{ slug: "home-scroll-animation", name: "Home Scroll Animation ", value: { style: { opacity: 0 }, triggers: [{ type: "scroll", offsetBot: "30%", stepsA: [{ opacity: 0, x: "0px", y: "100px", z: "0px" }, { opacity: 1, transition: "transform 400ms ease 0, opacity 300ms ease 0", x: "0px", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "logo-image-on-load", name: "Logo Image On Load", value: { style: { width: "100%" }, triggers: [] } },
		{ slug: "logo-link-sticky", name: "Logo Link Sticky", value: { style: {}, triggers: [{ type: "scroll", offsetBot: "0%", stepsA: [{ opacity: 1, transition: "opacity 700ms ease 0, transform 700ms ease 0", x: "0px", y: "0px", z: "0px" }], stepsB: [{ opacity: 0, transition: "opacity 350ms ease 0, transform 700ms ease 0", x: "0px", y: "-100px", z: "0px" }] }] } },
		{ slug: "logo-image-sticky", name: "Logo Image Sticky", value: { style: { x: "0px", y: "-100px", z: "0px" }, triggers: [{ type: "scroll", stepsA: [{ opacity: 1, transition: "opacity 700ms ease 0, transform 700ms ease 0", x: "0px", y: "-60px", z: "0px" }], stepsB: [{ opacity: 0, transition: "opacity 350ms ease 0, transform 700ms ease 0", x: "0px", y: "-120px", z: "0px" }] }] } },
		{ slug: "slidecaptionheader-2", name: "SlideCaptionHeader 2", value: { style: { opacity: 0, scaleX: 0.7000000000000005, scaleY: 0.7000000000000005, scaleZ: 1 }, triggers: [{ type: "slider", stepsA: [{ wait: "350ms" }, { opacity: 1, transition: "transform 800ms ease-out-quad 0, opacity 500ms ease 0", scaleX: 1, scaleY: 1, scaleZ: 1 }], stepsB: [{ wait: "500ms" }, { opacity: 0, transition: "opacity 200 ease 0, transform 200 ease 0", scaleX: 0.7000000000000005, scaleY: 0.7000000000000005, scaleZ: 1 }] }] } },
		{ slug: "slide-learn-more", name: "Slide Learn More", value: { style: { opacity: 0 }, triggers: [{ type: "slider", stepsA: [{ wait: "1100ms" }, { opacity: 1, transition: "opacity 500ms ease-out-quad 0" }], stepsB: [{ wait: "500ms" }, { opacity: 0, transition: "opacity 200 ease 0" }] }] } }
	]);
});
